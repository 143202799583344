import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "call-out-boxes"
    }}>{`Call Out Boxes`}</h1>
    <p>{`Make sure all call-outs are consistent in purpose. Don't use multiple highlights next to each other. If there's more than 2 styles, or 2 instances on the page, consider the overall hierarchy of information and what should be brought to the top or called out in a thoughtful/meaningful way`}</p>
    <p>{`Provide examples for our system`}</p>
    <p><strong parentName="p">{`C`}</strong>{`all `}<strong parentName="p">{`T`}</strong>{`o `}<strong parentName="p">{`A`}</strong>{`ction (CTA) – more here`}</p>
    <p>{`Info Boxes – more here`}</p>
    <p>{`Highlight Boxes – more here`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      